


@font-face {
    font-family: 'Jost*';
    src: url('jost-700-bold.woff2') format('woff2'),
         url('jost-700-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-700-bolditalic.woff2') format('woff2'),
         url('jost-700-bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-600-semi.woff2') format('woff2'),
         url('jost-600-semi.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-600-semiitalic.woff2') format('woff2'),
         url('jost-600-semiitalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}



@font-face {
    font-family: 'Jost*';
    src: url('jost-500-medium.woff2') format('woff2'),
         url('jost-500-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-500-mediumitalic.woff2') format('woff2'),
         url('jost-500-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-400-book.woff2') format('woff2'),
         url('jost-400-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-400-bookitalic.woff2') format('woff2'),
         url('jost-400-bookitalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-300-light.woff2') format('woff2'),
         url('jost-300-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Jost*';
    src: url('jost-300-lightitalic.woff2') format('woff2'),
         url('jost-300-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}
