/* TABLETS */
@media screen and (min-width: 768px) { 
	
    
    /* SIDEBAR */
    .with-sidebar { 
        float: left; width: 61%;
        }
    .sidebar { 
        float: left; width: 34%; margin-left: 5%; margin-top: 0.4em;
		}
	
	/* ALIGNMENTS */
	.alignnone {
		margin: 3em 0; 
		}
	.aligncenter {
		margin: 2.2em auto; 
		}
	.alignleft,
	.alignright {
		max-width: 50%; 
		}
	.alignright {
		float:right; margin: .4em 0 1em 2em;
		}
	.alignleft {
		float: left; margin: .4em 2em 1em 0;
		}
	
	/* TOP10 Popular Posts Widget */
	.tptn_posts_widget img {
		max-height: 94px;
		}
	.tptn_title {
		margin-top: 0.3em;
		}
	
	/* Forms */
	input:not([type=submit]):not([type=button]):not([type=file]):not([type=radio]):not([type=checkbox]), 
	select {
		max-width: 360px; 
		}
    
	/* HEADER */
	.nav-menu a {
		margin: 0 12px;
		}
	/* SINGLE PAGE MENU WITH ICONS */
	.menu-with-icons a:before {
		margin-right: 10px; font-size: 20px; margin-top: -4px;
		}
		
	
	/* PAGES */	
	.entry-header {
		padding-top: 3em; 
		}
	.entry-content {
		margin-top: 2.4em; 
		}
 	
	/* PROCESS */
	.row [class^="col-"]:nth-child(n) .process:after,
	.elementor-column:nth-child(n) .process:after { 
		display: inline-block; 
		}
    .row [class^="col-"]:last-child .process:after,
	.elementor-column:last-child .process:after { 
		display: none; 
		}
    
    /* CONTACT FORM */
	.contact-form p {
		width: 50%; float: left;
		}
	.contact-form p:nth-of-type(1) {
		padding-right: 32px;
		}
	.contact-form p:nth-of-type(n+3){
		 width: 100%;
		}	
    
    /* MEDIA GRID */
	.media-grid .media-cell {
 		float: left; width: 25%; padding: 0 18px; margin-bottom: 20px;
		}
	
	/* MEDIA BOX */
	.media-grid .media-cell {width: 45%;
		}
    
	
    /* TIMELINE */
    .event {
        padding: 0 1em 3em 4em;
        }
    .event h3 {
		margin-left: -2.6em;
		}
    .event h2 + p i,
	.event h2 + p img {
		font-size: 28px; width: 72px; line-height: 72px; left: -20px; top: -26px;
        }
    
	/* PORTFOLIO SINGLE */
	.portfolio-single .entry-content img,
	.portfolio-single .entry-content .fluid-width-video-wrapper,
	.portfolio-single .entry-content iframe,
	.portfolio-single .entry-content video {
		margin-bottom: 3em;
		}
	
	/* BLOG */
		
	/* format chat */
	.chat-row > div {
		display: inline-block;
		}
	.chat-author {
		text-align: right; width: 100px; margin-top: 5px; font-size: 14px; text-transform: uppercase;
		}
		
	/* BLOG SINGLE */
	.related-posts .media-grid .media-cell {
 		float: left; width: 33.33%;
		}
	
	/* COMMENTS */
    .comments-area {
        padding: 2em 0 1em;
        }
    .comments-area .commentlist {
        padding-bottom: 2em;
        }
    .comments-area article {
        padding: .8em 0; margin-top: 1em;
        }
    .comment-meta img {
        max-width: 66px; position: absolute; top: 14px; left: 0;
        }
    .comment-meta,
    .comment-content {
        margin-left: 84px;
        }
    .comments-area ol ol {
        margin-left: 8%
        }
	
	/* Comment Form */
    #respond form p { 
        margin-bottom: 0; 
        }
    .comment-form-author,
    .comment-form-email,
    .comment-form-url{
        float: left; width: 30%; margin-right: 3%;
        }
    .comment-form-url {
        width: 34%; margin-right: 0;
        }
    .form-allowed-tags,
    .form-submit { 
        clear: left; 
        }
    .form-submit {
        padding-top: 2em;
        }
		
	/* FOOTER WIDGETS */
    .site-footer .widget-area .widget { 
        width: 33.3%; float: left; padding: 0 1.5em 1em 1em;
        }	
    .site-footer .widget-area .widget:first-child { 
        padding-left: 0;
        }
    .site-footer .widget-area .widget:nth-child(3n) { 
        padding-right: 0;
        }
		
	/* Social Feed */
	.social-feed ul li {
		width: 50%; 
		}
	.social-feed ul li img {
		max-width: 100%; width: 100%;
		}
		
}



/* DESKTOPS */	
@media screen and (min-width: 992px) { 

	
	body {
		font-size: 18px;
		}
	.site-middle {
		padding-bottom: 60px;
		}
    /* custom scrollbars for webkit browsers */
	*::-webkit-scrollbar{
		width: 6px;
		}
	*::-webkit-scrollbar-thumb{
		/* background-color: rgba(28, 38, 43, 0.25); */
		}
	*::-webkit-scrollbar:horizontal {
		height: 8px; 
		}
	
	/* HOME PAGE */
	
	.classic-page-layout.ready .home-heading {
		transform: translateX(-12%);
		}

	/* SIDEBAR */
    .with-sidebar { 
        width: 68%;
        }
    .sidebar { 
        width: 25.8%; margin-left: 6%;
        }
	.sidebar .inner-wrapper-sticky {
		padding-bottom: 46px;
		}
	
	/* SECTION TITLE */
    .section-title,
	.elementor-widget-wrap > .elementor-widget:not(:first-child):not(:only-of-type) .section-title { 
		margin: 3em 0 1em; 
		}
	.elementor .section-title { 
		margin: 3em 0 1em; 
		}
	
	/* MEDIA GRID */
	.media-grid {
		margin-left: -20px; margin-right: -20px;
		}
	.related-posts .media-grid {
		margin-left: -10px; margin-right: -10px;
		}

	/* CLIENTS */
	html .client img {
		max-height: 40px; max-width: 94px;
		}
	
	/* FUN FACT */
	.fun-fact {
		margin-bottom: 0;
		}
	/* BLOG SINGLE */
	/* .content-area:not(.with-sidebar) .blog-single .featured-image, 
	.content-area:not(.with-sidebar) .blog-single .related-posts {
		width: 122%; margin-left: -11%;
		} */
	.blog-single .entry-content { 
		max-width: 600px; 
		}

	/* Social Feed Widget */
	.social-feed ul li {
		width: 33.3%; 
		}
		
	
	/* BUTTONS */
	.button.huge {
		font-size: 13px; padding: 24px 44px;
		}  
	
	
	/* portfolio single nav  */
	.portfolio-nav {
		margin-bottom: 3em;
		}

	/* COMMENTS */
    .comment-content p {
        font-size: 16px;
        }
		
}





/* HIGH RES DESKTOPS */	
@media screen and (min-width: 1300px) { 	
		

	/* increase column gutter on large screens */
	.row {
	   margin-left: -30px;
	   margin-right: -30px;
	   }
   .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
	   padding-left: 30px; padding-right: 30px;
	   }
	   
	/* BLOG */
	.content-area:not(.with-sidebar) .post .entry-content > p img:not(.alignleft):not(.alignright),
	.content-area:not(.with-sidebar) .blog-single .entry-content .gallery,
	.entry-content > .gallery,
	.entry-content .wp-block-embed,
	.entry-content .wp-block-gallery {
		width: 134%; max-width: none; margin-left: -17%; 
		}
	
	/* ALLIGNMENTS */
	.post .alignright, 
	.post .wp-caption.alignright {
		max-width: 66%;	margin-right: -101px; margin-left: 2em;
		}
	.post .alignleft, 
	.post .wp-caption.alignleft {
		max-width: 66%;	margin-left: -101px; margin-right: 2em;
		}	
	}