@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?14501059');
  src: url('../font/fontello.eot?14501059#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?14501059') format('woff2'),
       url('../font/fontello.woff?14501059') format('woff'),
       url('../font/fontello.ttf?14501059') format('truetype'),
       url('../font/fontello.svg?14501059#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?14501059#fontello') format('svg');
  }
}
*/
 
 [class^="pw-icon-"]:before, [class*=" pw-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.pw-icon-menu:before { content: '\e800'; } /* '' */
.pw-icon-book:before { content: '\e801'; } /* '' */
.pw-icon-doc-text:before { content: '\e802'; } /* '' */
.pw-icon-spotify-1:before { content: '\e803'; } /* '' */
.pw-icon-book-alt:before { content: '\e804'; } /* '' */
.pw-icon-book-1:before { content: '\e805'; } /* '' */
.pw-icon-bookmark:before { content: '\e806'; } /* '' */
.pw-icon-pencil-1:before { content: '\e807'; } /* '' */
.pw-icon-pen:before { content: '\e808'; } /* '' */
.pw-icon-apple:before { content: '\e809'; } /* '' */
.pw-icon-lightbulb:before { content: '\e80a'; } /* '' */
.pw-icon-down-open-big:before { content: '\e80b'; } /* '' */
.pw-icon-angle-down:before { content: '\e80c'; } /* '' */
.pw-icon-circle-empty:before { content: '\e80d'; } /* '' */
.pw-icon-book-open-1:before { content: '\e80e'; } /* '' */
.pw-icon-check:before { content: '\e80f'; } /* '' */
.pw-icon-check-empty:before { content: '\e810'; } /* '' */
.pw-icon-chat:before { content: '\e811'; } /* '' */
.pw-icon-pencil-alt:before { content: '\e812'; } /* '' */
.pw-icon-undo:before { content: '\e813'; } /* '' */
.pw-icon-at:before { content: '\e814'; } /* '' */
.pw-icon-article-alt:before { content: '\e815'; } /* '' */
.pw-icon-doc:before { content: '\e816'; } /* '' */
.pw-icon-comment-alt2:before { content: '\e817'; } /* '' */
.pw-icon-comment:before { content: '\e818'; } /* '' */
.pw-icon-search:before { content: '\e819'; } /* '' */
.pw-icon-scissors-outline:before { content: '\e81a'; } /* '' */
.pw-icon-flow-merge:before { content: '\e81b'; } /* '' */
.pw-icon-gift:before { content: '\e81c'; } /* '' */
.pw-icon-chart-bar-outline:before { content: '\e81d'; } /* '' */
.pw-icon-check-1:before { content: '\e81e'; } /* '' */
.pw-icon-check-outline:before { content: '\e81f'; } /* '' */
.pw-icon-pencil-2:before { content: '\e820'; } /* '' */
.pw-icon-mobile-1:before { content: '\e821'; } /* '' */
.pw-icon-laptop:before { content: '\e822'; } /* '' */
.pw-icon-plus:before { content: '\e823'; } /* '' */
.pw-icon-desktop-2:before { content: '\e824'; } /* '' */
.pw-icon-facebook:before { content: '\e825'; } /* '' */
.pw-icon-twitter:before { content: '\e826'; } /* '' */
.pw-icon-linkedin-squared:before { content: '\e827'; } /* '' */
.pw-icon-map:before { content: '\e828'; } /* '' */
.pw-icon-pinterest-circled:before { content: '\e829'; } /* '' */
.pw-icon-github-circled:before { content: '\e82a'; } /* '' */
.pw-icon-behance:before { content: '\e82b'; } /* '' */
.pw-icon-dribbble:before { content: '\e82c'; } /* '' */
.pw-icon-address:before { content: '\e82d'; } /* '' */
.pw-icon-lastfm:before { content: '\e82e'; } /* '' */
.pw-icon-rss:before { content: '\e82f'; } /* '' */
.pw-icon-vimeo-squared:before { content: '\e830'; } /* '' */
.pw-icon-forrst:before { content: '\e831'; } /* '' */
.pw-icon-skype:before { content: '\e832'; } /* '' */
.pw-icon-picasa:before { content: '\e833'; } /* '' */
.pw-icon-youtube-play:before { content: '\e834'; } /* '' */
.pw-icon-flickr:before { content: '\e835'; } /* '' */
.pw-icon-tumblr:before { content: '\e836'; } /* '' */
.pw-icon-blogger:before { content: '\e837'; } /* '' */
.pw-icon-delicious:before { content: '\e838'; } /* '' */
.pw-icon-digg:before { content: '\e839'; } /* '' */
.pw-icon-vcard:before { content: '\e83a'; } /* '' */
.pw-icon-wordpress:before { content: '\e83b'; } /* '' */
.pw-icon-stackoverflow:before { content: '\e83c'; } /* '' */
.pw-icon-foursquare:before { content: '\e83d'; } /* '' */
.pw-icon-xing:before { content: '\e83e'; } /* '' */
.pw-icon-sina-weibo:before { content: '\e83f'; } /* '' */
.pw-icon-soundcloud:before { content: '\e840'; } /* '' */
.pw-icon-fivehundredpx:before { content: '\e841'; } /* '' */
.pw-icon-slideshare:before { content: '\e842'; } /* '' */
.pw-icon-android:before { content: '\e843'; } /* '' */
.pw-icon-contacts:before { content: '\e844'; } /* '' */
.pw-icon-windows:before { content: '\e845'; } /* '' */
.pw-icon-vkontakte:before { content: '\e846'; } /* '' */
.pw-icon-myspace:before { content: '\e847'; } /* '' */
.pw-icon-meetup:before { content: '\e848'; } /* '' */
.pw-icon-feather-1:before { content: '\e849'; } /* '' */
.pw-icon-reddit:before { content: '\e84a'; } /* '' */
.pw-icon-stumbleupon-circled:before { content: '\e84b'; } /* '' */
.pw-icon-path:before { content: '\e84c'; } /* '' */
.pw-icon-dropbox:before { content: '\e84d'; } /* '' */
.pw-icon-wine:before { content: '\e84e'; } /* '' */
.pw-icon-thumbs-up:before { content: '\e84f'; } /* '' */
.pw-icon-deviantart:before { content: '\e850'; } /* '' */
.pw-icon-grooveshark:before { content: '\e851'; } /* '' */
.pw-icon-thumbs-down:before { content: '\e852'; } /* '' */
.pw-icon-steam:before { content: '\e853'; } /* '' */
.pw-icon-quora:before { content: '\e854'; } /* '' */
.pw-icon-angellist:before { content: '\e855'; } /* '' */
.pw-icon-icq:before { content: '\e856'; } /* '' */
.pw-icon-lock:before { content: '\e857'; } /* '' */
.pw-icon-attach-outline:before { content: '\e858'; } /* '' */
.pw-icon-doc-alt:before { content: '\e859'; } /* '' */
.pw-icon-videocam-outline:before { content: '\e85a'; } /* '' */
.pw-icon-mail:before { content: '\e85b'; } /* '' */
.pw-icon-search-alt:before { content: '\e85c'; } /* '' */
.pw-icon-heart:before { content: '\e85d'; } /* '' */
.pw-icon-user:before { content: '\e85e'; } /* '' */
.pw-icon-news:before { content: '\e85f'; } /* '' */
.pw-icon-camera:before { content: '\e860'; } /* '' */
.pw-icon-graduation:before { content: '\e861'; } /* '' */
.pw-icon-bookmark-empty-1:before { content: '\e862'; } /* '' */
.pw-icon-vine:before { content: '\e863'; } /* '' */
.pw-icon-badge:before { content: '\e864'; } /* '' */
.pw-icon-education:before { content: '\e865'; } /* '' */
.pw-icon-play:before { content: '\e866'; } /* '' */
.pw-icon-gallery:before { content: '\e867'; } /* '' */
.pw-icon-eye-outline:before { content: '\e868'; } /* '' */
.pw-icon-mic-outline:before { content: '\e869'; } /* '' */
.pw-icon-external:before { content: '\e86a'; } /* '' */
.pw-icon-image:before { content: '\e86b'; } /* '' */
.pw-icon-feather:before { content: '\e86c'; } /* '' */
.pw-icon-left-open-big:before { content: '\e86d'; } /* '' */
.pw-icon-right-open-big:before { content: '\e86e'; } /* '' */
.pw-icon-up-open-big:before { content: '\e86f'; } /* '' */
.pw-icon-circle:before { content: '\e870'; } /* '' */
.pw-icon-details:before { content: '\e871'; } /* '' */
.pw-icon-home:before { content: '\e872'; } /* '' */
.pw-icon-left-arrow:before { content: '\e873'; } /* '' */
.pw-icon-right-arrow:before { content: '\e874'; } /* '' */
.pw-icon-close:before { content: '\e875'; } /* '' */
.pw-icon-quote:before { content: '\e876'; } /* '' */
.pw-icon-pin:before { content: '\e877'; } /* '' */
.pw-icon-way:before { content: '\e878'; } /* '' */
.pw-icon-music:before { content: '\e879'; } /* '' */
.pw-icon-location-outline:before { content: '\e888'; } /* '' */
.pw-icon-coffee:before { content: '\e88b'; } /* '' */
.pw-icon-book-open:before { content: '\e88c'; } /* '' */
.pw-icon-circle-thin:before { content: '\e88d'; } /* '' */
.pw-icon-minus:before { content: '\e89a'; } /* '' */
.pw-icon-phone-outline:before { content: '\e8a8'; } /* '' */
.pw-icon-tree:before { content: '\e8ab'; } /* '' */
.pw-icon-pencil:before { content: '\e8b3'; } /* '' */
.pw-icon-plane-outline:before { content: '\e8bc'; } /* '' */
.pw-icon-tablet:before { content: '\e8bf'; } /* '' */
.pw-icon-instagram-1:before { content: '\e8cc'; } /* '' */
.pw-icon-coverflow-empty:before { content: '\f00d'; } /* '' */
.pw-icon-isight:before { content: '\f039'; } /* '' */
.pw-icon-gplus-1:before { content: '\f0d5'; } /* '' */
.pw-icon-hand-spock-o:before { content: '\f259'; } /* '' */
.pw-icon-hand-pointer-o:before { content: '\f25a'; } /* '' */
.pw-icon-hand-peace-o:before { content: '\f25b'; } /* '' */
.pw-icon-snapchat:before { content: '\f2ac'; } /* '' */
.pw-icon-imdb:before { content: '\f2d8'; } /* '' */
.pw-icon-newspaper:before { content: '\f4f0'; } /* '' */